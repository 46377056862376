import React, { useState, useEffect } from "react";
import { POLL_DELAY } from "../shared/config";
import { checkService, allServices } from "../services/checkServices";
import SeverityList from "./SeverityList";
import StatusCard from "./StatusCard/StatusCard";

export default function StatusReports() {
  const [timer, setTimer] = useState<any>();
  const [lastUpdate, setLastUpdate] = useState<string>("refreshing...");
  const [serviceStatuses, setStatuses] = useState<ServiceStatus[]>([]);

  useEffect(() => {
    setStatuses([]);
    checkEverything();
    setTimer(setInterval(() => checkEverything(), POLL_DELAY));

    async function checkEverything() {
      console.log(`checking services ${formatDate(new Date())}`)
      for (const service of allServices) {
        const status: ServiceStatus | undefined = await checkService(service);
        if (status) {
          setStatuses((old) => {
            const filtered = old.filter((x) => x.name !== status.name);
            return [...filtered, status];
          });
        }
      }
      setLastUpdate(formatDate(new Date()));
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(timer);
      setTimer(null);
    };
    // eslint-disable-next-line
  }, []);

  function formatDate(date: Date): string {
    return date.toLocaleString();
  }

  return (
    <div className="w-full mt-[50px] flex bg-slate-50 flex-col justify-center items-center h-full">
      <header className="t-0 mb-[45px] ml-[5%] self-start">
        Check here for updates about Daily AI system availability. Updated every
        10 minutes
      </header>

      {serviceStatuses.map((status: ServiceStatus) => {
        return <StatusCard key={status.name} service={status}></StatusCard>;
      })}
      {!serviceStatuses?.length && (
        <div className="flex items-center justify-center min-h-[65%]">
          <span>LOADING...</span>
        </div>
      )}

      <p>
        Last Updated <strong>{lastUpdate}</strong>
      </p>
      <SeverityList />
    </div>
  );
}
