import styled from "styled-components";
import Checkmark from "../icons/Checkmark";
import CloseCircle from "../icons/CloseCircle";

interface Props {
  children?: any;
  status: Statuses;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 11px 11px 11px 26px;
`;

const TextBlurb = styled.span`
  margin-right: 4px;
`;

export default function StatusIndicator({ status }: Props) {
  const statusString = status.toString();
  return (
    <Container>
      <TextBlurb>{statusString}</TextBlurb>
      { status === "Offline" && <CloseCircle />}
      { status === "Online" && <Checkmark />}
    </Container>
  );
}