import Logo from "./icons/Logo";
import ReportButton from "./ReportButton";
import { VERSION_NUMBER } from "../shared/config";

export default function Header() {
  return (
    <div className="flex justify-between w-full shadow-md">
      <Logo />
      <span className="m-auto font-bold text-center">DailyAI Operational Statuses {VERSION_NUMBER}</span>
      <ReportButton />
    </div>
  )
}