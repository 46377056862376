export const allServices: Service[] = [
  {
    name: "DailyAI CRM",
    url: "https://mortgage.dailyai.io/login",
    description: "This service is all of the UI at mortgage.dailyai.io.",
    method: "GET",
    type: "html",
  },
  {
    name: "Mongo API",
    url: "https://api.dailyai.io/getVersion",
    method: "GET",
    description:
      "This service is IO for contacts, transactions and many business entities. Think the contacts or transactions tables.",
  },
  {
    name: "Mongo ETL",
    method: "GET",
    url: "https://etl.dailyai.io/getVersion",
    description: "This service transfers data from firebase to mongo.",
  },
  {
    name: "Cloud Functions",
    url: "https://us-central1-dailyai-live.cloudfunctions.net/getVersion",
    description:
      "This service is most of the backend. The majority of operations within DailyAI are handled by this service.",
  },
  {
    name: "Drip Campaign and Milestone API",
    url: "https://crm-prod-api.dailyai.io/",
    description: "This service handles Drip Campaigns and Milestones.",
    method: "GET",
    severity: 2,
  },
  {
    name: "STAGING: Cloud Functions",
    url: "https://us-central1-dailyai-staging.cloudfunctions.net/getVersion",
    description:
      "This service is most of the backend. The majority of operations within DailyAI are handled by this service.",
    environment: "STAGING",
  },
  {
    name: "STAGING: DailyAI CRM",
    url: "https://staging.dailyai.io/login",
    description: "This service is all of the UI at staging.dailyai.io.",
    method: "GET",
    type: "html",
    environment: "STAGING",
  },
  {
    name: "STAGING: Drip Campaign and Milestone API",
    url: "https://crm-staging-api.dailyai.io/",
    description: "This service handles Drip Campaigns and Milestones.",
    method: "GET",
    severity: 2,
    environment: "STAGING",
  },
];

export async function checkService(
  service: Service
): Promise<ServiceStatus | undefined> {
  let status = {
    name: service.name,
    status: "Offline",
    version: "",
    message: service.description,
    environment: service.environment ?? "PROD",
    severity: service.severity ?? 1,
  } as ServiceStatus;
  try {
    const type = service.type ?? "json";
    const PARAMS = {
      method: service.method ?? "POST",
      headers:
        service.type === "json"
          ? {
              Accept: "application/json",
              "Content-Type": "application/json",
            }
          : undefined,
    };
    const response = await fetch(service.url, PARAMS);
    let data: any;
    if (type === "html") {
      data = await response.text();
      const frontEndVersion = findFrontEndVersionNumber(data);
      if (frontEndVersion) {
        status.status = "Online";
        status.version = frontEndVersion;
      }
    } else {
      data = await response.json();
      if (data.version) {
        status.status = "Online";
        status.version = data.version;
      }
    }
    return status;
  } catch (e) {
    return status;
  }
}

function findFrontEndVersionNumber(html: string): string | null {
  const beginning = html.search("version");
  const versionChunk = html.slice(
    beginning,
    beginning + "version 99.99.99".length
  );
  const versionNumber = versionChunk.match(RegExp(/\d+\.\d+\.\d+/g));
  if (versionNumber) {
    return versionNumber[0];
  }
  return null;
}
