import styled from "styled-components";

const StyledList = styled.div`
  width: 90%;

  ul {
    margin-top: 0;
    list-style: none;
    padding: 12px;
  }

  p {
    font-weight: 600;
    display: inline-block;
    padding: 0;
    margin: 0;
  }
`;

const ListTitle = styled.div`
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 900;
`;

export default function SeverityList() {
  return (
    <StyledList>
      <ListTitle>SEVERITY SCALE</ListTitle>
      <ul>
        <li><p>Sev 1</p> - Critical Impact/System Down. Complete system outage.</li>
        <li><p>Sev 2</p> - Significant Impace/Severe downgrade of services</li>
        <li><p>Sev 3</p> - Minor impact/Most of the system is functioning properly.</li>
        <li><p>Sev 4</p> - Low Impact/Informational.</li>
      </ul>
    </StyledList>
  );
}