import StatusReports from "./components/StatusReports";
import Header from "./components/Header";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Home() {
  return (
    <Container>
      <Header />
      <StatusReports />
    </Container>
  );
}