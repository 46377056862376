import styled from "styled-components";

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 15vw;
  height: 15vw;
  max-height: 94px;
  max-width: 94px;
  min-width: 35px;
  min-height: 35px;

  margin: 5px;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 7px;

  svg {
    width: 100%; height: 100%;
  }
`;

export default function Logo() {
  return (
    <StyledLogo>
      <svg viewBox="0 0 215 219" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.7005 128.145C48.7005 129.7 50.0143 131.012 51.5714 131.012H60.5732C71.0348 131.012 78.0904 123.481 78.0904 114.007C78.0904 104.532 71.0348 97.0012 60.6219 97.0012H51.5714C50.0143 97.0012 48.7005 98.2645 48.7005 99.8192V128.145ZM54.4422 125.668V102.297H60.6219C67.7261 102.297 72.2027 107.496 72.2027 114.007C72.2027 120.517 67.7261 125.668 60.6219 125.668H54.4422Z" fill="#001535" />
        <path d="M124.378 128.145C124.378 129.7 125.692 131.012 127.249 131.012H140.387C141.847 131.012 143.014 129.797 143.014 128.34C143.014 126.834 141.847 125.668 140.387 125.668H130.12V99.4305C130.12 97.8272 128.806 96.5639 127.249 96.5639C125.692 96.5639 124.378 97.8272 124.378 99.4305V128.145Z" fill="#001535" />
        <path d="M150.364 128.583C150.364 130.138 151.678 131.449 153.235 131.449C154.792 131.449 156.106 130.138 156.106 128.583V114.784L167.736 100.937C168.125 100.451 168.32 99.9164 168.32 99.2362C168.32 97.7786 167.2 96.5639 165.692 96.5639C164.913 96.5639 164.086 96.904 163.6 97.5356L153.333 109.682L143.163 97.5842C142.579 96.904 141.801 96.5639 140.925 96.5639C139.368 96.5639 138.103 97.7786 138.103 99.382C138.103 100.014 138.297 100.645 138.784 101.228L150.364 115.027V128.583Z" fill="#001535" />
        <path d="M76.3301 126.926C75.8435 127.557 75.6975 128.092 75.6975 128.675C75.6975 130.132 76.914 131.347 78.3738 131.347C79.1036 131.347 79.9795 130.91 80.4661 130.375L100.416 106.568V118.423H93.7986C92.2902 118.423 91.1224 119.638 91.1224 121.095C91.1224 122.601 92.2902 123.767 93.7986 123.767H100.416V128.48C100.416 130.035 101.681 131.347 103.287 131.347C104.844 131.347 106.109 130.035 106.109 128.48V99.3281C106.109 97.7248 104.844 96.4615 103.287 96.4615C102.508 96.4615 101.681 96.753 100.951 97.579L76.3301 126.926Z" fill="url(#paint0_linear)" />
        <path d="M112.348 128.48C112.348 130.035 113.662 131.347 115.219 131.347C116.776 131.347 118.09 130.035 118.09 128.48V99.3281C118.09 97.7248 116.776 96.4615 115.219 96.4615C113.662 96.4615 112.348 97.7248 112.348 99.3281V128.48Z" fill="url(#paint1_linear)" />
        <path d="M142.912 33.5546L153.957 49.6981C154.699 50.6215 154.108 51.9041 152.849 52.1029L133.38 54.2353C132.194 54.2738 131.366 53.1163 131.809 52.1544L134.026 47.345C119.189 41.1153 102.223 39.9292 86.3861 44.3124C69.7594 48.7212 55.0786 59.4625 45.6622 73.9808C40.9909 81.1597 37.2961 89.1755 35.3551 97.6081C33.4141 106.041 32.9057 114.743 34.0516 123.233C36.2695 140.374 44.7095 156.681 57.4287 168.348C70.3823 179.929 87.615 186.871 104.951 187.255C122.374 187.874 140.048 181.615 153.531 170.518C167.174 159.494 176.774 143.312 179.692 126.005C182.845 108.61 179.477 90.1645 170.36 74.9059C169.939 74.1299 170.061 73.0206 170.838 72.6005C171.616 72.1805 172.727 72.3023 173.148 73.0782C183.316 89.0134 187.265 108.309 184.064 126.652C181.184 145.143 171.302 162.361 156.98 174.433C142.659 186.505 123.899 193.43 104.983 193.097C86.2268 192.838 67.4875 185.787 53.2363 173.222C38.985 160.657 29.6296 142.958 26.9654 124.253C25.6332 114.9 26.0293 105.175 27.9447 95.9537C30.0946 86.6458 33.7638 77.8413 39.039 69.7742C49.355 53.7265 65.565 41.3564 84.0677 36.255C101.62 31.1054 120.957 32.2146 138.029 39.0823L140.394 33.9522C140.75 32.7562 142.331 32.7049 142.912 33.5546Z" fill="url(#paint2_linear)" />
        <defs>
          <linearGradient id="paint0_linear" x1="75.6975" y1="113.904" x2="118.09" y2="113.904" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1488CC" />
            <stop offset="1" stopColor="#2B32B2" />
          </linearGradient>
          <linearGradient id="paint1_linear" x1="75.6975" y1="113.904" x2="118.09" y2="113.904" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1488CC" />
            <stop offset="1" stopColor="#2B32B2" />
          </linearGradient>
          <linearGradient id="paint2_linear" x1="153.164" y1="124.388" x2="55.0481" y2="38.0684" gradientUnits="userSpaceOnUse">
            <stop stopColor="#001535" />
            <stop offset="0.427083" stopColor="#0F2F6F" />
            <stop offset="1" stopColor="#0B3494" />
          </linearGradient>
        </defs>
      </svg>
    </StyledLogo>
  );
}