export default function NotFound() {
  return (
    <div className="App">
      404 Page Not Found :(
      <div>
        <p>
          Perhaps Google is down, you can check <a href="status.firebase.io">here</a>
        </p>
      </div>
    </div>
  );
}