import { DAILY_SUPPORT } from "../shared/config";

export default function ReportButton() {
  function reportIncident() {
    window.location.href = `mailto:${DAILY_SUPPORT}?subject=Daily AI Outage Incident`;
  }

  return (
    <button className="mt-auto mb-auto mr-5 bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded" onClick={reportIncident}>
      Report Incident
    </button>
  );
}