import styled from "styled-components";
import StatusIndicator from "./StatusIndicator";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-height: 40px;
  min-width: 330px;
  margin: 12px;
  padding: 0;
  border-radius: 4px;
  background-color: #e6f7ff;
  color: #000000;
`;

const CardHeader = styled.div`
  top: 0;
  width: 100%;

  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  background-color: ${(props) =>
    props.theme.status === "Online"
      ? props.theme.environment === "PROD"
        ? "#0047AF"
        : "#B6C2D9"
      : "#ad2323"};

  color: ${(props) => (props.theme.environment !== "PROD" ? "black" : "white")};
`;

const CardTitle = styled.div`
  padding: 11px 0px 11px 26px;
`;

const CardBody = styled.div`
  display: block;

  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;

  border-color: ${(props) =>
    props.theme.status === "Online"
      ? props.theme.environment === "PROD"
        ? "#0047AF"
        : "#B6C2D9"
      : "#ad2323"};

  background-color: ${(props) =>
    props.theme.status === "Online" ? "#E6F7FF" : "#eca9a9"};
  opacity: 0.9;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  padding: 16px 26px 16px 26px;

  min-height: 80px;
  font-size: 18px;
  width: 100%;
`;

interface Props {
  children?: any;
  service: ServiceStatus;
}

export default function StatusCard({ children, service }: Props) {
  return (
    <Card>
      <CardHeader
        theme={{ status: service.status, environment: service.environment }}
      >
        <CardTitle>
          {service.name} {service.version}
        </CardTitle>
        <StatusIndicator status={service.status}></StatusIndicator>
      </CardHeader>
      <CardBody
        theme={{ status: service.status, environment: service.environment }}
      >
        {service.status === "Online" && <div>{service.message}</div>}
        {service.status === "Offline" && (
          <strong>SEV: {service.severity}</strong>
        )}
      </CardBody>
    </Card>
  );
}
